<script lang="ts" setup>
import type { ILeaseSummaryField, ILeaseSummaryFieldValue } from '@register'
import { createColumnHelper } from '@tanstack/vue-table'
import { useTanstackTableVariants } from '@ui/components/TanstackTable'
import { formatRentIncreaseValue } from '@register/components/Review/Form/RentReviewTable'

type RentIncreaseTableValue = {
  option: number | undefined
  date: string
  rentIncreases: string
}
interface Props {
  field: ILeaseSummaryField
  value: ILeaseSummaryFieldValue
}
const props = defineProps<Props>()

const currentValue = computed(() => {
  return props.value?.value ?? props.field.value?.value
})

const { dateFormat: divisionDateFormat } = useDivisions()
const { dateFormat: leaseDateFormat } = useCurrentLease()
const dayjs = useDayjs()

const isOptionRentIncreaseTable = computed(
  () => props.field.id === 'OptionRentIncreaseTable',
)

// TABLE HEADER
const columnHelper = createColumnHelper<RentIncreaseTableValue>()
const columns = [
  columnHelper.accessor('option', {
    header: 'Option',
    meta: {
      visible: () => isOptionRentIncreaseTable.value,
    },
  }),
  columnHelper.accessor('date', {
    header: 'Date',
  }),
  columnHelper.accessor('rentIncreases', {
    header: 'Increase',
  }),
]

// TABLE ITEMS
const tableValues = computed<RentIncreaseTableValue[]>(() => {
  if (!Array.isArray(currentValue?.value)) return []

  return currentValue?.value?.map((item) => {
    return {
      option: item?.option,
      date: item?.date
        ? dayjs(item.date, divisionDateFormat.value).format(
            leaseDateFormat.value,
          )
        : 'Not defined',
      rentIncreases: formatRentIncreaseValue(item),
    }
  })
})

const items = computed(() => {
  if (!tableValues.value) {
    return []
  }

  return [...tableValues.value].sort((a, b) => {
    if (!a || !b) return 0

    // Compare by 'option'
    if (isOptionRentIncreaseTable.value && a.option! !== b.option!) {
      return a.option! - b.option!
    }

    // If 'option' is equal, compare by 'date'
    const aDate = dayjs(a.date)
    const bDate = dayjs(b.date)
    if (aDate) {
      return bDate.isAfter(aDate) ? -1 : 1
    } else {
      // Convert the option values to numbers before comparison
      const optionA = parseInt(String(a), 10)
      const optionB = parseInt(String(b), 10)

      return optionB - optionA
    }
  })
})

const variant = useTanstackTableVariants({
  slots: {
    base: 'mb-2 overflow-hidden rounded-sm text-sm -mx-4',
    thead: 'border-b-transparent',
    theadTr: 'bg-transparent h-auto',
    theadTh: 'text-xs leading-none text-gray-400 font-normal',
    tbody: 'divide-transparent',
    tbodyTr: 'h-auto',
    tbodyTd: 'text-xs leading-none text-white',
  },
  variants: {
    stripedRows: {
      false: {
        tbodyTr: 'bg-transparent',
      },
    },
    padding: {
      md: {
        theadTh: 'py-3',
        tbodyTd: 'py-3',
      },
    },
  },
})
</script>

<template>
  <span v-if="tableValues.length === 0"> N/A </span>

  <span v-else>
    <TanstackTable
      :data="items"
      :columns="columns"
      aria-describedby="Rent increase"
      :variant="variant"
      :hoverable="false"
    >
      <template #item-date="obj">
        <time>{{ obj.value }}</time>
      </template>
    </TanstackTable>
  </span>
</template>
